

















import {Vue, Component, Watch} from "vue-property-decorator";
import AllFriendsGuoType from "./index"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import FriendComponents from "@/views/Friend/components/FriendComponents/index.vue";
import { FriendCircleStore } from "../../Store/index"
import {getFriends} from "@/views/Friend/FriendCircle/Server";
import { ItemType } from "@/views/Friend/FriendCircle/Store/indexType";

@Component({name: "AllFriendsGuo",components:{ PullDownUpList,ListBottom,FriendComponents }})
export default class AllFriendsGuo extends Vue implements AllFriendsGuoType{
    PullDown = false;
    UpDownBool = true;
    StopUp = false
    List:ItemType[] = []

    mounted(){
        this.List = FriendCircleStore.getListCircle
        this.handleUpData(true)
    }

    handlePullDown(): void {
        this.PullDown = true
        let upData = { ...FriendCircleStore.getUpDataCircle,pageNo:1 }
        FriendCircleStore.setUpDataCircle(upData)
        this.handleUpData(true)
    }

    handleUpDown(): void {
        if ( this.StopUp )return;
        FriendCircleStore.setUpDataCircle({ ...FriendCircleStore.getUpDataCircle,pageNo:FriendCircleStore.getUpDataCircle.pageNo+1 })
        this.handleUpData()
    }

    handleUpData(bool=false){
        getFriends().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < FriendCircleStore.getUpDataCircle.pageSize )this.StopUp = true;
            if ( bool )
                FriendCircleStore.setListCircle( res );
            else
                FriendCircleStore.setListCircle( FriendCircleStore.getListCircle.concat(res) );
        })
    }

    get getState(){
        return FriendCircleStore.getNavState
    }
    @Watch("getState")
    changeState(newVal:number){
        switch ( newVal ) {
            case 0:
                FriendCircleStore.setUpDataCircle({ ...FriendCircleStore.getUpDataCircle,queryAuth:0,pageNo:1 })
                break;
            case 1:
                FriendCircleStore.setUpDataCircle({ ...FriendCircleStore.getUpDataCircle,queryAuth:1,pageNo:1 })
                break;
            case 2:
                FriendCircleStore.setUpDataCircle({ ...FriendCircleStore.getUpDataCircle,queryAuth:2,pageNo:1 })
                break;
            default:
                FriendCircleStore.setUpDataCircle({ ...FriendCircleStore.getUpDataCircle,queryAuth:0,pageNo:1 })
                break
        }
        this.UpDownBool = true
        this.handleUpData(true)
    }

    get getList(){
        return FriendCircleStore.getListCircle
    }
    @Watch("getList")
    changeList(newVal:ItemType[]){
        this.List = newVal
    }
}
