





































import { Vue,Component } from "vue-property-decorator"
import FriendCircleType from "./FriendCircle"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import AllFriendsGuo from "@/views/Friend/FriendCircle/components/AllFriendsGuo/index.vue";
import { UserType } from "@/Api/Basics/InterfaceType"
import { FriendCircleStore } from "./Store/index"
import ZoomPage from "@/impView/PageSafety"
import Storage from "@/util/Storage"
import {getMyAboutUnread} from "@/views/Friend/Server";

@Component({ name:"FriendCircle",components:{ AllFriendsGuo,HeadTop} })
export default class FriendCircle extends ZoomPage implements FriendCircleType{
    writeIcon = require("./Img/write.png")
    loadingShow = true;
    navList = ["全国","全省","本校"]
    navIndex = 0
    userInfo:UserType = {}
    unreadCount = 0
    homeBool = false

    mounted(){
        this.loadingShow = false
        this.userInfo = Storage.GetData_ && JSON.parse(Storage.GetData_("UserData")) || {}
        this.initData()
        this.handleUpData()
    }

    initData(){
        let obj = Object.keys(this.$route.query)
        if ( obj.length ) {
            let Index = obj.findIndex(a=>a == 'homeFriend')
            if ( Index >= 0 ) this.homeBool = true
            else this.homeBool = false;
        }
    }

    handleChangeNav(e:number){
        if ( e != this.navIndex ){
            this.navIndex = e
            FriendCircleStore.setNavState(e);
        }
    }

    handleUpData(){
        getMyAboutUnread().then(res=>{
            this.unreadCount = res["count"]
        })
    }

    get hideShow(){
        return this.unreadCount > 0 && true || false
    }

    get getPhoto(){
        let userInfo = Storage.GetData_ && JSON.parse(Storage.GetData_("UserData")) || {}
        return userInfo.headImg && userInfo.headImg || require("@/assets/icon/Basics/default.png")
    }

}
